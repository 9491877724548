/* src/App.css */

/* Set a dark background color for the entire app */
body {
  background-color: #1a1a1a;
  color: #fff; /* Text color for dark background */
}

/* Style the navigation links for the dark theme */
.horizontal-tabs a {
  text-decoration: none;
  padding: 10px 20px;
  color: #fff; /* Text color for links in the dark theme */
  background-color: #333; /* Link background color in the dark theme */
  border-radius: 5px;
  margin: 0 5px;
  transition: background-color 0.3s;
}

.horizontal-tabs a:hover {
  background-color: #555; /* Hover background color for links in the dark theme */
}

/* Add any additional styles for the dark theme here */

.button-style-white {
  background-color: #fff; /* Dark background color */
  color: #333; /* White text color */
  padding: 8px 16px; /* Adjust padding as needed */
  border: 1px solid #ccc;
  border-radius: 4px;
  cursor: pointer;
  text-decoration: none;
  transition: background-color 0.3s;
}
.button-style {
  background-color: #333; /* Dark background color */
  color: #fff; /* White text color */
  padding: 6px; /* Adjust padding as needed */
  border: 1px solid #ccc;
  border-radius: 4px;
  cursor: pointer;
  text-decoration: none;
  transition: background-color 0.3s;
}

.depressed-input {
    background-color: #333;
    color: #fff;
    border: 1px solid #555;
    padding: 8px;
    border-radius: 4px;
}

/* Optional: Add transition for a smooth effect */
.depressed-input:focus {
    outline: none;
    background-color: #444;
    transition: background-color 0.3s ease;
}

.clipped-text {
	overflow: hidden;
	white-space: nowrap;
}


.positive-action {
  background-color: #28a745;
}

.positive-action:disabled {
  background-color: #999;
}

.form-container {
      background-color: #555;
      padding: 20px;
      border-radius: 10px;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    }

.form-label {
      display: block;
      margin-bottom: .2rem;
      font-weight: 600;
      font-size: .9rem;
    }

.form-input {
      width: 100%;
      padding: 0.3rem;
      margin-bottom: 0.5rem;
      border: 1px solid #444;
      border-radius: 4px;
      background-color: #333;
      color: #fff;
    }

.form-button {
      padding: 10px 20px;
      border: none;
      border-radius: 4px;
      color: #fff;
      cursor: pointer;
      margin-right: 10px;
    }

.hover\:bg-555:hover {
  background-color: #555;
}
.gsi-material-button {
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  -webkit-appearance: none;
  background-color: WHITE;
  background-image: none;
  border: 1px solid #747775;
  -webkit-border-radius: 20px;
  border-radius: 20px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  color: #1f1f1f;
  cursor: pointer;
  font-family: 'Roboto', arial, sans-serif;
  font-size: 14px;
  height: 40px;
  letter-spacing: 0.25px;
  outline: none;
  overflow: hidden;
  padding: 0 12px;
  position: relative;
  text-align: center;
  -webkit-transition: background-color .218s, border-color .218s, box-shadow .218s;
  transition: background-color .218s, border-color .218s, box-shadow .218s;
  vertical-align: middle;
  white-space: nowrap;
  width: auto;
  min-width: min-content;
}

.gsi-material-button .gsi-material-button-icon {
  height: 20px;
  margin-right: 12px;
  min-width: 20px;
  width: 20px;
}

.gsi-material-button .gsi-material-button-content-wrapper {
  -webkit-align-items: center;
  align-items: center;
  display: flex;
  -webkit-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: nowrap;
  flex-wrap: nowrap;
  height: 100%;
  justify-content: space-between;
  position: relative;
  width: 100%;
}

.gsi-material-button .gsi-material-button-contents {
  -webkit-flex-grow: 1;
  flex-grow: 1;
  font-family: 'Roboto', arial, sans-serif;
  font-weight: 500;
  overflow: visible;
  text-overflow: ellipsis;
  vertical-align: top;
}

.gsi-material-button .gsi-material-button-state {
  -webkit-transition: opacity .218s;
  transition: opacity .218s;
  bottom: 0;
  left: 0;
  opacity: 0;
  position: absolute;
  right: 0;
  top: 0;
}

.gsi-material-button:disabled {
  cursor: default;
  background-color: #ffffff61;
  border-color: #1f1f1f1f;
}

.gsi-material-button:disabled .gsi-material-button-contents {
  opacity: 38%;
}

.gsi-material-button:disabled .gsi-material-button-icon {
  opacity: 38%;
}

.gsi-material-button:not(:disabled):active .gsi-material-button-state, 
.gsi-material-button:not(:disabled):focus .gsi-material-button-state {
  background-color: #303030;
  opacity: 12%;
}

.gsi-material-button:not(:disabled):hover {
  -webkit-box-shadow: 0 1px 2px 0 rgba(60, 64, 67, .30), 0 1px 3px 1px rgba(60, 64, 67, .15);
  box-shadow: 0 1px 2px 0 rgba(60, 64, 67, .30), 0 1px 3px 1px rgba(60, 64, 67, .15);
}

.gsi-material-button:not(:disabled):hover .gsi-material-button-state {
  background-color: #303030;
  opacity: 8%;
}
.notification-bell {
  animation: bell 1s 1s both infinite;
}

.notification-count {
  position: absolute;
  z-index: 1;
  top: -6px;
  right: -5px;
  line-height: 25px;
  font-size: 15px;
  border-radius: 50%;
  color: #fff;
}

@keyframes bell {
  0% {
    transform: rotate(0);
  }

  10% {
    transform: rotate(30deg);
  }

  20% {
    transform: rotate(0);
  }

  80% {
    transform: rotate(0);
  }

  90% {
    transform: rotate(-30deg);
  }

  100% {
    transform: rotate(0);
  }
}
.contact-button-style{
  color: #fff;
  padding: 2px;
  border: 1px solid #ccc;
  border-radius: 4px;
  cursor: pointer;
  text-decoration: none;
}

.inline-icon{
  margin-right: 0.2em;
  display: inline;
  vertical-align: text-bottom;
  width: 1.2rem;
  height: 1.2rem;
}
