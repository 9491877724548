/* src/components/Dashboard.css */
.dashboard-tiles {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  padding-top: 20px;
}

.dashboard-tile {
  flex: 0 0 calc(33.33% - 20px);
  padding: 20px;
  margin: 10px;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 5px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.dashboard-tile h2 {
  font-size: 16px;
  margin-bottom: 10px;
  color: #011;
}

.dashboard-tile p {
  font-size: 18px;
  font-weight: bold;
  color: #011;
}

.hover\:bg-eee:hover {
  background-color: #eee;
}
