  /* Shaking effect */
  .shake {
    animation: shake 0.75s ease-in-out;
  }
  
  @keyframes shake {
    0%, 100% { transform: translateX(0); }
    25% { transform: translateX(-5px); }
    50% { transform: translateX(5px); }
    75% { transform: translateX(-5px); }
  }
  
  /* Flipping effect */
  .flip {
    transform: rotateY(180deg);
  }
  
  .fade-out {
    opacity: 0.65;
  }
  
  .fade-in {
    opacity: 1;
  }

  .slide-out {
    transform: translateX(-100%);
    animation: slideOut 0.3s forwards;

  }

  @keyframes slideOut {
    from {
      transform: translateX(0%);
    }
    to {
      transform: translateX(-100%);
    }
  }
  
  /* Slide in animation */
  .slide-in {
    transform: translateX(100%);
    animation: slideIn 0.3s forwards;
  }
  
  @keyframes slideIn {
    from {
      transform: translateX(100%);
    }
    to {
      transform: translateX(0%);
    }
  }

.word-wrap {
    white-space: normal;       /* Allows wrapping at spaces */
    overflow-wrap: anywhere;   /* Ensures long words break if necessary */
    word-break: normal;        /* Prevents breaking words if spaces are present */
}

