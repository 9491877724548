/* src/components/Navigation.css */
.horizontal-tabs {
  list-style: none;
  padding: .25rem;
  display: flex;
  justify-content: space-between;
  background-color: #000000;
  color: #333;
}

.horizontal-tabs li {
  margin: 0;
  padding: .25rem;
  background-color: #000000;
  color: #333;
}

.horizontal-tabs a {
  text-decoration: none;
  padding: .2rem;
  /* border: 1px solid #ccc; */
  border-radius: 2px;
  margin: 0 .1rem;
  background-color: #000000;
  color: #333;
  transition: background-color 0.3s;
}

.horizontal-tabs a:hover {
  background-color: #ddd;
}

.horizontal-tabs .last-tab {
  margin-left: auto;
}

.dot{
  height: .75em;
  width: .75em;
  background-color: #00ff00;
  border-radius: 50%;
  display: inline-block;

}

.align-bottom{
vertical-align: bottom;
} 

.align-top{
  vertical-align: top;
}



.bg-ddd {
  background-color: #ddd;
}